.Pagination-buttom-size{
    width: 45px !important;
    height: 45px !important;
    border: solid 1px #94979B!important;
    
}

.Pagination-icon-margin{

    margin: auto;

}