* {
	box-sizing: border-box;
}
.modal-header {
	border-bottom: none;
}

.modal-content {
	border: none !important;
}

.Fielder-modals-header-border {
	border-bottom: 0 none;
}
.Fielder-modals-header-txt-title {
	margin-left: 5%;
	margin-top: 30px;
	margin-right: 40px;

	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: 0.7px;
	text-align: left;
	color: #1b253a;
}

.modal-header-style{
	justify-content: left !important;
}

.modal-footer {
	border-top: none;
}

.Fielder-cards-border {
	border: none;
}

h1 {
	color: #1b253a !important;
	font-size: 16px !important;
	letter-spacing: 0.7px;
}

h2 {
	color: #354052 !important;
	font-size: 200% !important;
	letter-spacing: normal;
}

.Fielder-cards-colum {
	min-width: 50%;
}

.Fielder-cards-profile-picture {
	height: 50px;
	width: 50px;
}

.Fielder-cards-txt-label-input {
	font-family: 'Lato';
	font-size: 9px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 3;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.Fielder-cards-font-inputs {
	font-family: 'Lato' !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.69 !important;
	letter-spacing: normal !important;
	text-align: left !important;
	color: #354052 !important;
}

.Fielder-cards-btnSave-margin {
	margin-top: 30% !important;
	margin-left: 30% !important;
}

.Fielder-modal-btnSave-size {
	width: 271px;

	height: 56px;
}

.Fielder-agent-btnTeam-size {
	width: 20px;
	height: 10px;
}

/***********************************************************************************/

/************************************ Botton ***************************************/

.btn-outline-primary {
	color: #dc5c87 !important;
	border-color: #dc5c87;
	background-color: #f5e6ed !important;
	border-color: #f5e6ed !important;
	text-align: center;
}

.btn-outline-primary:hover {
	background: #dc5c87 !important;
	border: #dc5c87 !important;
	color: #f5e6ed !important;
}

/*++++++++++++++++CHANGE BORDER ON SUCCESS+++++++++++++++++++++++++++*/
.btn.btn-success:focus,
.btn-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 92, 135);
}

.btn.btn-success:not(:disabled):not(.disabled):active:focus,
.btn.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 92, 135);
}

.btn-light {
	width: 120px;
	height: 20px;
	font-family: 'Lato Regular';
	font-size: 12px;
	position: absolute;
	text-align: center;
	padding: 0 10px 5px 5px;
}
/***********************************************************************************/
/*** Login ***/

.Fielder-login-topmenu-text {
	width: 55px;
	height: 21px;
	font-family: 'Lato';
	font-size: 17px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.24;
	letter-spacing: normal;
	text-align: left;
	color: #354052 !important;
}

/***********************************************************************************/

/************************************ MAIN PAGES ***********************************/

.Fielder-mainpage-lefttextusr {
	margin-top: 30px;
	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: 0.7px;
	color: #1b253a;
}

.Fielder-mainpage-lefttextnumusr {
	margin: auto;
	margin-right: 2px;
	font-family: 'Lato';
	font-size: 41px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #1b253a;
}

.Fielder-mainpage-txtTitle {
	margin-left: 5%;
	margin-top: 30px;
	margin-right: 40px;
	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: 0.7px;
	text-align: left;
	color: #1b253a;
}

.Fielder-mainpage-addtext {
	font-family: 'Lato';
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.22;
	letter-spacing: normal;
	text-align: left;
	color: #94979b;
}

.Fielder-mainpage-downloadicon {
	margin-top: 26px;
	margin-right: 50px;
}

.Fielder-mainpage-addicon {
	margin-left: 5px;
}
/*********************************    Global form control ******************************************/

.form-control:focus {
	border-color: #db3262;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(219, 50, 98);
}
.form-control {
	opacity: 0.6;
	border-radius: 6px;
	border: solid 1px #979797;
}

/**************************************************************************************************/
/************************************   Dropdown Top Menu *****************************************/
.dropdown-item.active,
.dropdown-item:active {
	color: #ffffff;
	background-color: #db3262;
}

/***********************************************************************************************/
/*********************************** Global Checkbox *************************************************/
.checkbox {
	width: 200px;
	margin-left: 0%;
}
.checkbox .tag {
	color: #354052;
	display: block;
	float: right;
	font-weight: bold;
	position: relative;
	width: 120px;
	padding-left: 10px;
}
.checkbox label {
	display: inline;
}
.checkbox .labelCheckbox {
	display: none;
}
.labelCheckbox + label {
	-webkit-appearance: none;
	background-color: #fafafa;
	border: 2px solid #f5e6ed;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 9px;
	display: inline-block;
	position: relative;
	overflow: hidden;
}
.labelCheckbox:checked + label:after {
	width: 16px;
	height: 16px;
	border-radius: 3px;
	content: '';
	background-color: #dc5c87;
	left: 1px;
	position: absolute;
	top: 1px;

	padding-top: 10px;
}

/***********************************************************************************************/
/**************************************  Text Links ********************************************/

.Fielder-text-link {
	color: #dc5c87 !important;
}

/***********************************************************************************************/

.Fielder-popUp-image-title {
	position: relative;
	top: 3px;
}

.Fielder-popUp-title {
	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.69;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.Fielder-popUp-dropdown-arrow-addBranch .dropdown-toggle::after {
	margin-left: 310px;
}

.Fielder-popUp-dropdown-arrow .dropdown-toggle::after {
	margin-left: 250px;
}

.tag-selection {
    background: #f3f3f3;
    border-radius: 10px 10px 10px 10px;
}


.login-input-sizeñ{
	height: 65px !important;
}