.MyOperation-bg-grey {
	background: #f5f7fa;
}

.myoperation-navbar {
	width: 60%;
}

.MuiBadge-dot {
	background-color: #dc5c87 !important;
}

.myoperation-nav-item {
	border-bottom: 2px solid #e8e8e8d0;
	color: #1b253a !important;
	width: 60%;
	min-width: 150px;
	font-size: 16px;
}

.confirmation-text {
	width: 287px;
	height: 76px;
	font-family: 'Lato';
	font-size: 16px !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	margin-left: 15% !important;
	margin-right: 15% !important;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: center;
	color: var(--dark-grey-blue);
}

.danger {
	width: 41.3px;
	height: 35.5px;
	object-fit: contain;
}

.myoperacion-icon-separator {
	border-left: 1px solid #a5a5a5;
	background-color: #fff;
}

.myoperation-nav-active {
	border-bottom: 2px solid #f8445b;
}

.myoperation-title {
	color: #1b253a !important;
	font-size: 16px;
	letter-spacing: 0.7px;
	font-family: 'Lato', 'Roboto', sans-serif;
}

.myoperation-count-title1 {
	color: #1b253a !important;
	font-size: 16px;
	letter-spacing: 0.7px;
	float: right;
	margin-top: 14px;
	margin-left: 5px;
}
.myoperation-count-title2 {
	color: #1b253a !important;
	font-size: 28px;
	letter-spacing: 0.7px;
	float: right;
}

::placeholder {
	color: #1b253acc;
}

.myoperation-selected-icon {
	height: 25px !important;
	width: 25px !important;
}
.tag-settings {
	width: 180px !important;
	background-color: lightgrey;
	border-radius: 10px;
}
.myoperation-input-select {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 5px;
	width: 100% !important;
	box-shadow: white;
	color: #1b253acc;
	border: 1px solid #cbced2;
	border-color: #cbced2 !important;
}

.MuiInputLabel-outlined {
	z-index: 0 !important;
}

.filter-color-hover:focus {
	outline: none !important;
	border: 1px solid #f8445b !important;
	box-shadow: 0 0 10px #f8445b !important;
	border-color: #f8445b !important;
}

.myoperation-input-select-filter {
	width: 100% !important;
	border: none !important;
	box-shadow: white;
	color: #1b253acc;
}

.icon {
	width: 22px;
	height: 22px;
}

.full-width {
	width: 100%;
}

.popup-label {
	font-size: 16px;
}

.small-popup-label {
	font-size: 11px;
}

.font-size-task-type{
	font-size: 12px;
}

.no-padding {
	padding: 0px !important;
	margin: 0px !important;
}

.no-border {
	border: none !important;
}

.myoperation-link {
	color: #354052;
}

.MyOperation-img-input {
}

.MyOperation-schedule {
	border-color: #b7bdc4 !important;
	width: 60%;
}

.MyOperation-form-hint {
	text-align: center;
	color: #dc5c87;
	font-family: 'Lato';
	margin-top: 50px;
}

.MyOperation-display-none {
	display: none;
}
.MyOperation-alert-icon {
	/* padding-top: 20%;
  margin-left: -30% !important; */
	position: absolute;
	right: 0;
	bottom: 10px;
}
.import-tasks {
	margin-top: 40px;
	cursor: alias;
}
.import-tasks input {
	display: none;
}

.import-tasks label {
	width: 100% !important;
}

.import-tasks label span {
	width: 100% !important;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 35px;
	padding-right: 35px;
}

.modal-shadow .modal {
	z-index: 1040;
}

.field-alert-icon {
	position: absolute;
	left: 98%;
	top: 50%;
	display: none;
}

.dropdown-menu {
	left: -70px !important;
}

.dropDownCalendar {
	position: absolute;
	width: 10px !important;
	top: 5px;
	right: 19px;
}

.Color:hover {
	fill: pink;
}

.range-select select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url(../../assets/icons/dropdown-icon.svg);
	background-repeat: no-repeat;
	background-position-x: 90%;
	background-position-y: 16px;
	background-size: 8px;
}

.reschedule-commentTask-scroll {
	overflow-y: scroll;
	height: 100px;
}

.numberOfTaskPosition {
	top: 45%;
}

.textFormat-taskGroupDetail-task {
	font-family: 'Lato';
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #4a4a4a;
}

.textFormat-taskGroupDetail-idCustom {
	font-family: 'Lato';
	font-size: 13px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #0a0a0a;
}

.left-dashed-line {
	border-left: 2px dashed #ccc4c4;
}

.roundCheckbox {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: pointer;
	height: 16px;
	left: 0;
	position: absolute;
	top: 0;
	width: 16px;
}

.containerScroll {
	height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.create-edit-modal-btnsave-size {
	width: 145px;
	height: 36px;
}

.font-size-taskgroup {
	font-size: 12px !important;
}

.cursor-pointer {
	cursor: pointer;
}
.forms-close:focus {
	outline: none;
}

.taskModule-deleteForm {
	background-color: transparent !important;
	border: none !important;
}

.taskModule-deleteForm:focus {
	outline: transparent !important;
}

.myop-alert-color {
	color: #dc5c87;
}

.text-area-size {
	width: 480px !important;
}

.myoperation-icon {
	position: absolute;
	left: 93%;
	top: 55%;
	height: 20px;
	display: none;
}

.modify-status-icon {
	position: absolute;
	left: 93%;
	top: 40%;
	height: 20px;
	display: none;
}

.taskGroupSize {
	font-size: 9px !important;
}

.buttonDetailcss {
	width: 90px !important;

	height: 27.5px !important;

	border-radius: 4px !important;

	border: solid 1px #dc5c87 !important;
	background: rgb(255, 255, 255) !important;
}

.rateTask-title-size {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.69;
	letter-spacing: normal;
	text-align: left;
	color: var(--dark-grey-blue);
}

.rate-title-size {
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: var(--dark-grey-blue);
}

.tooltipCustom {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltipCustom .tooltiptextCustom {
	visibility: hidden;
	width: 300px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	font-size: 11px;
}

.tooltipCustom:hover .tooltiptextCustom {
	visibility: visible;
}

.tooltipCustomProgram {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltipCustomProgram .tooltiptextCustomProgram {
	visibility: hidden;
	width: 300px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	font-size: 11px;
}

.tooltipCustomProgram:hover .tooltiptextCustomProgram {
	visibility: visible;
	z-index: 99999;
}

.taskAction-container {
	height: 60px;
	transition: bottom 0.3s ease;
	position: fixed;
	z-index: 99;
	bottom: 0;
	background: white;
	background-color: transparent;
}

.taskAction-red-rectangle {
	background-color: #f8445b;
	width: 10px !important;
}
.taskAction-number-selected {
	font-family: 'Lato';
	font-size: 36px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: normal;
	text-align: left;
	color: #f8445b;
}

.taskAction-text-selected {
	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: #52575a;
}

.taskAction-text-icon {
	font-family: 'Lato';
	font-size: 9px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: -0.27px;
	text-align: left;
	color: var(--dark-grey-blue);
}

.taskAction-background {
	background: white;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.closeTaskAction {
	position: absolute;
	right: 0;
	width: 32px;
	height: 32px;
	opacity: 0.3;
	top: 2px;
	border: none;
}
.closeTaskAction:hover {
	opacity: 1;
}
.closeTaskAction:before,
.closeTaskAction:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	top: 1px;
	background-color: #333;
}
.closeTaskAction:before {
	transform: rotate(45deg);
}
.closeTaskAction:after {
	transform: rotate(-45deg);
}

.colsize-cross {
	width: 60px !important;
	height: 34.9833px !important;
}

.reassign-partner-textarea {
	width: 90%;
	margin-left: 5%;
	border-width: 1px;
	border-color: #f0f0f0;
	border-radius: 5px;
	margin-top: 10px;
	resize: none;
	height: 80px;
}

.reassign-input-row {
	width: 90% !important;
	margin-left: 5%;
	display: flex;
	flex-wrap: wrap;
}
.reassign-row {
	width: 90% !important;
	margin-left: 5%;
}

.borderNone div {
	border: none !important;
}
.iconSubMenuSize {
	height: 22px;
	width: 25px;
}

.table-width-td {
	min-width: 140px;
}

.text-format-task-finish {
	text-align: center;
	color: #dc5c87;
	font-family: 'Lato';
}

.text-format-task-finished {
	text-align: center;
	font-family: 'Lato';
	font-size: 15px;
}

::-webkit-scrollbar {
	-webkit-appearance: none !important;
	width: 5px !important;
}
::-webkit-scrollbar-thumb {
	border-radius: 3px !important;
	background-color: rgba(0, 0, 0, 0.5) !important;
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

.track-horizontal {
	overflow-x: hidden !important;
}
.track-horizontal-height {
	height: 300px !important;
}


.taskAction-container-fix{
	width: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: fixed;
  	bottom: 0;
    z-index: 99;
	/*right: 0.5%; */
  	left: 50%;
  	transform: translateX(-50%);
}