.error-message-ceter-icon{
    
        width: 54.1px;
        margin: auto;
}

.error-message-margin{
    margin-bottom: 3px;
}


.error-message-btn-margin{
    margin: auto;
}

.error-message-modal-body{

    padding: 0rem !important;
}

.modal-footer{
    border-top: 0px !important;
}

.color-numberSuccess{
    font-weight: bold;
    color: #8ceb68;

}

.color-numberLinked{
    font-weight: bold;
    color: #f8445b;
}

.error-icon-size{
    width: 20px
}