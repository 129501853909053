
.rd-body {
    padding-top: 20px;
    padding-bottom: 40px;
    background-color: white !important;
}
.rd-nav-title {
    color: #354052;
    font-size: 18px;
}

.rd-active {
    border-bottom: 2px solid #949393d0 !important;
}

.rd-navbar {
    width:50%;
    margin: 0;
}

.rd-text {
    font-size: 16px;
    color: #354052;
}

.rd-text-green {
  font-size: 16px;
  color: #f6f8fa;
}

.rd-nav-item {
    border-bottom: 2px solid #e8e8e8d0;
}

.route-text-large {
    font-size: 24px
}

.route-input {
    max-width: 260px !important;
}

.rd-task-element-name {
    font-size: 13px;
    color: #4a4a4a;
    text-transform: uppercase;
}

.rd-task-element-name2 {
    font-size: 13px;
    color: #4a4a4a;
}

.severity-high {
    font-size: 13px;
    color: #FF4146;
    text-transform: uppercase;
}

.severity-med {
    font-size: 13px;
    color: #FE8B0D;
    text-transform: uppercase;
}

.severity-low {
    font-size: 13px;
    color: #FFCF00;
    text-transform: uppercase;
}

.rd-task-element-id {
    font-size: 13px;
    color: #4a4a4a;
    font-weight: bold;
}

.rd-task-element-act {
    font-size: 13px;
    color: #4a4a4a;
    text-transform: capitalize;
}

.rd-task-status{
  font-size: 13px !important;
}

.rd-task-element-date {
    font-size: 11px;
    font-family: Helvetica, sans-serif;
    color: #1a1a1a;
    text-transform: uppercase;
}

.rd-task-element-location {
    font-size: 13px;
    color: #6c6c6c;
}

.rd-task-element-status {
    font-size: 13px;
    color: white;
    max-width: 150px !important;
    width: 100%;
    height: 30px;
    border-radius: 26.5px;
    text-align: center;
    border:none;
}

.rd-timeline {
    list-style-type: none;
    position: relative;
    max-width: 500px;
}

.rd-timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

.rd-list-item {
    margin: 20px 0;
    width: 80%;
    padding-left: 1vw;
}

.rd-list-item:before {
    content: '';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 21px;
    width: 22px;
    height: 22px;
    z-index: 400;
}

.rd-list-item.finish:before {
    content: '';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22e864;
    left: 21px;
    width: 22px;
    height: 22px;
    z-index: 400;
}

.ellipse-default {
    width: 20px;
    height: 20px;
    border: solid 1px #707070;
    border-radius: 50%;
    background-color: #f4f4f4;
  }

  .elli {
    position: relative;
    bottom: 7px;
    left: -4px;
  }

  .ellipse-selected {
    width: 16px;
    height: 16px;
    border: solid 1px #707070;
    border-radius: 50%;
    background-color: #dc5c87;
  }

.rd-droppable-bg {
    height: inherit !important;
    width: 70%;
}

.rd-droppable-bg2 {
    height: inherit !important;
    width: 100%;
}

.route-details-editable {
    border-style: dashed;
    border-color: #cbcbcb81;
}

.route-details-loading-view{
  height: 400px;
  width: 40%;
  margin-left: 30%;

}

.route-details-search-loading-view{
    height: 600px;
    width: 40%;
    margin-left: 30%;
  
  }
.rd-activity-table-pic {
    width: 25px;
}
.rd-activity-user-image {
    padding-top: 3px;
}
.rd-activity-user-pic {
    width: 35px;
    height: 35px;
    margin-top: 3px;
    float: left !important;
}
.rd-activity-user-text {
    color: #c1c1c1;
    font-size: 16px;
    height: 35px;
    float: left !important;
    padding-top: 6px;
    padding-left: 8px;
}

.rd-activity-table {
    font-size: 13px;
    color: #c1c1c1;
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    margin-bottom: 40px !important;
}
.rd-activity-table-row{
  border: 1px solid #e6ebf0 !important;
}
.rd-activity-table-cell{
  padding: 7px 40px 7px 16px !important;
}
.rd-add-task{
  height: 70px;
}
.rd-add-task-row{
  padding-top: 5px;
  padding-left: 15px;
  width: 50% !important;
}
.rd-add-task-row span {
  font-size: 15px !important;
  padding-top: 10px;
}

.rd-add-activity-view{
  height: 600px !important;
  margin-top: 10px !important;
  overflow-y: scroll !important;
}

.rd-emergencies-table {
    width: 100% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    margin-bottom: 40px !important;
}

.rd-emergencies-agent-span {
    margin-left: -40px !important;
    margin-bottom: 20px;
}

.rd-emergencies-row{
  width: 100%;
  height: 110px !important;
}

.rd-emergencies-row h4{
  width: 100%;
  height: 40px !important;
  margin-bottom: 0 !important;
  vertical-align: middle;
  display: contents;
}

.rd-emergencies-row span{
  width: 100%;
  height: 20px !important;
  margin-bottom: 5px !important;
  font-size: 12px;
}

.rd-emergencies-row p{
  width: 100%;
  height: 50px !important;
  overflow-y: scroll !important;
}

.rd-click-image {
    cursor: pointer;
}

.rd-disable{
    opacity: .2;
}

.rd-tasks-to-show{
    font-size: 18px !important;
    margin-left: 15px !important;
    font-style: bold;
  }

  .rd-clear-button{
    background: none !important;
      font-size: 12px !important;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: #354052 !important;
    border: none !important;
  }

  .rd-alert-icon{
    position:absolute;
    height: 20px;
    display: none;
}

.rd-text-size{
  padding-top: 5px;
  padding-left: 15px;
}

.route-details-input {
  min-height: 40px;
  height: auto;
  resize:none;
  background-color: #8d909417 !important;
  border: 1px !important;
  border-color: #000000;
  border-radius: 5px;
  padding-left: 7px;
}

.route-details-input-highlighter {
  min-height: 40px;
  height: auto;
  resize:none;
  background-color: #fed1d7 !important;
  border: 1px !important;
  border-color: #9c2f4d;
  border-radius: 5px;
  padding-left: 7px;
}