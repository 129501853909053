.create-task-image {
	width: 90px !important;
	height: 50px;
}

.title {
	font-size: 16px !important;
}

.modal-dialog {
	max-height: 100% !important;
}
.create-task-select-border {
	border-color: #ffffff00 !important;
	background-color: #ffffff00 !important;
	height: 40px !important;
	max-height: 40px;
}

.tab-background-color {
	background: #ffffff !important;
	background-color: #ffffff !important;
}

.tab-input-color {
	background-color: #f7f7f7 !important;
}

.taskType-treeSelect {
	max-height: 400px;
	overflow: auto;
}

.checkbox {
	width: 200px;
	margin-left: 5%;
}

.checkbox .tag {
	color: #354052;
	display: block;
	float: right;
	font-weight: bold;
	position: relative;
	width: 120px;
	padding-left: 10px;
}

.checkbox label {
	display: inline;
}

.checkbox .labelCheckbox {
	display: none !important;
}

.labelCheckbox + label {
	-webkit-appearance: none;
	background-color: #fafafa;
	border: 2px solid #f5e6ed;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 9px;
	display: inline-block;
	position: relative;
	overflow: hidden;
}

.labelCheckbox:checked + label:after {
	width: 16px;
	height: 16px;
	border-radius: 3px;
	content: '';
	background-color: #dc5c87;
	left: 1px;
	position: absolute;
	top: 1px;
	padding-top: 10px;
}

.square-checkbox {
	width: 22px;
}

.full-width {
	width: 100% !important;
}

.border-radius-10 {
	border-radius: 10px;
}

.create-task-dropdown-size {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.title-padding {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.text-title {
	width: 69px;
	height: 15px;
	font-family: 'Lato';
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.react-datepicker__time-container {
	width: 90px !important;
}

.container .react-datepicker__time .react-datepicker__time-box {
	width: 90px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 90px !important;
}

.react-datepicker-popper {
	width: 400px !important;
	z-index: 9999 !important;
}

.react-datepicker__navigation--next--with-time {
	right: 90px !important;
}

.react-datepicker__day--selected {
	background-color: #dc5c87 !important;
	color: #ffffff !important;
}
.react-datepicker__day--selected:hover {
	background-color: #dc5c87 !important;
}

.react-datepicker__day:hover {
	background-color: #ffffff !important;
}

.react-datepicker__day--selected:hover {
	background-color: #dc5c87 !important;
}

.react-datepicker__day--today {
	color: #ce2b66;
}

.react-datepicker__time-list-item--selected {
	background-color: #dc5c87 !important;
}

.react-datepicker__day--keyboard-selected {
	background-color: #dc5c87 !important;
}

.react-datepicker__day--keyboard-selected:hover {
	background-color: #dc5c87 !important;
	color: #ffffff !important;
}

.react-datepicker__month-text--today {
	color: #ffffff;
}
.react-datepicker__day--keyboard-selected {
	color: #ffffff !important;
}

.react-select__control {
	border-color: #dc5c87 !important;
}

.react-select__control-username {
	font-size: 16px !important;
}

.CreateTask-margin {
	margin-left: 5rem !important;
}

.CreateTask-fieldContainer-icon {
	position: absolute;
	left: 101%;
	top: 25%;
	height: 20px;
	display: none;
}

.CreateTask-select-fieldContainer-icon {
	position: absolute;
	left: 99.1%;
	top: 19.9%;
	height: 20px;
	display: none;
}

.CreateTask-dir-desc-fieldContainer-icon {
	position: absolute;
	left: 101%;
	top: 55%;
	height: 20px;
	display: none;
}

.CreateTaskGroup-dir-assing-fieldContainer-icon {
	height: 20px;
	display: none;
}

.CreateTask-dir-task-fieldContainer-icon {
	position: absolute;
	left: 101%;
	top: 38%;
	height: 20px;
	display: none;
}

.CreateTask-severity-fieldContainer-icon {
	position: absolute;
	left: 101%;
	top: 60%;
	height: 20px;
	display: none;
}

.CreateTask-programDate-fieldContainer-icon {
	position: absolute;
	right: -3%;
	top: 52%;
	height: 20px;
	display: none;
}

.CreateTask-daypicker-img {
	position: absolute;
	margin-top: 13px;
	margin-left: -27px;
}

.CreateTask-align-left {
	text-align: left;
}

.CreateTask-padding-button {
	margin-left: 2vw;
}

.datePicker-position + .react-datepicker-popper {
	position: absolute;
	will-change: transform;
	top: 0px;
	left: -14% !important;
}

.custom-select-volumen-task {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;

	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.custom-select {
	/*-webkit-appearance: auto !important;*/
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-repeat: no-repeat;
	background-position-x: 96%;
	background-position-y: 16px;
	background-size: 8px;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #f5f7f9 !important;
}

.react-datepicker-wrapper {
	width: 95%;
}

#input-tags-edit-task {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.buttonFunction {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
	padding: 10px !important;
	border-radius: 7px !important;
}

.buttonFunction:hover {
	background-color: #f5f7f9 !important;
}

.switchSize {
	width: 50px !important;
	height: 33px !important;
}

.switchLabelSize {
	font-size: 12px !important;
	font-family: 'Lato' !important;
}

.color-box {
	background-color: white !important;
}

.sizeImageSource {
	width: 50px;
	height: 80px;
}
