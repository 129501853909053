h1 {
	font-size: 21px;
}

.td-navbar {
	width: 100%;
	margin: 0;
}

.td-nav-item {
	width: 20%;
	border-bottom: 2px solid #e8e8e8d0;
	font-size: 18px;
}

.EditForm-Signature-canvas {
	width: 85%;
	height: 90%;
	border: 0.5px solid #8a8a8a !important;
}

.sizeText {
	font-size: 12px !important;
}

.TaskModules-button {
	background-color: #fff !important;
	color: #000 !important;
	border: 0.5px solid #000 !important;
	width: 100% !important;
	max-height: 50px;
}
.button-color-action {
	background-color: #db2059 !important;
}

.TaskModules-scrollL {
	width: 200px;
	height: 200px;
	overflow: auto;
	color: var(--dark-grey-blue) !important;
}

.TaskModules-scrollL-a:hover {
	background-color: #fff !important;
	color: #0056b3 !important;
}

.TaskModules-scrollL-a {
	color: var(--dark-grey-blue) !important;
	background-color: #fff !important;
}

.TaskModules-scrollL-a:active {
	background-color: #fff !important;
	color: #0056b3 !important;
}

.TaskModules-scrollR {
	width: 500px;
	height: 200px;
	overflow: auto;
}

.TaskModules-img {
	width: 60px;
}
.TaskModules-div {
	border-bottom: 1px solid #8a8a8a;
}
.TaskModules-labelField {
	font-family: 'Lato';
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: normal;
	text-align: left;
	color: var(--dark-grey-blue);
}

.TaskModules-comment {
	font-family: 'Lato';
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.69;
	letter-spacing: normal;
	text-align: left;
}

.TaskModules-comment-content {
	font-size: 13px;
	color: #000;
}

.TaskModules-comment-title {
	font-size: 16px;
	color: #354052;
}

.td-nav-title {
	color: #354052;
	font-size: 18px;
}

.td-active {
	border-bottom: 4px solid #dc5c87;
}

.td-task-number {
	font-size: 24px !important;
}

.td-task-element-status {
	font-size: 15px;
	color: white;
	max-width: 150px !important;
	width: 100%;
	height: 50px;
	border-radius: 26.5px;
	text-align: center;
	border: none;
}

.color-attach-button {
	color: #dc5c87 !important;
}

.icon {
	height: 25px;
}

.task-details-editable {
	background-color: white !important;
	border: none;
}

.task-details-input {
	height: 35px;
	background-color: #8d909417 !important;
	border: none !important;
}

.task-details-input:disabled {
	color: #585858 !important;
}

.task-details-input-photo {
	background-color: #8d909417 !important;
	border: none !important;
}

.tag-button {
	background-color: #efefef !important;
	border-radius: 10px !important;
	font-size: 13px;
}

.td-hashtag-label {
	border-right: 0px solid white !important;
	background: white !important;
}

.td-profile-pic {
	width: 60%;
}

.td-profile-pic-small {
	width: 90%;
}

.ReactGridGallery_tile {
	margin: 15px !important;
	background-color: white !important;
	border: 0px solid white;
}

.agent-text {
	color: #354052;
	font-size: 16px;
}

.TaskModules-label {
	font-size: 15px;
	color: #354052;
}

.td-label-input {
	font-size: 9px;
	color: #354052;
}

.td-default-text {
	font-size: 16px;
}

.td-activity-user-pic {
	width: 60px;
}

.td-activity-user-text {
	color: #c1c1c1;
	font-size: 16px;
}

.td-activity-user-text-size{
	width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-activity-table {
	font-size: 13px;
	color: #c1c1c1;
	width: 100%;
	table-layout: fixed;
}

.td-activity-table:tr {
	border: black;
}

.td-activity-table-pic {
	width: 25px;
}

.dashed-box-border {
	border: dashed 2px #979797;
	min-height: 30px;
	border-radius: 10px;
}

.dashed-box-border-red {
	border: dashed 2px #c70101;
	min-height: 30px;
	border-radius: 10px;
}

.form-active {
	border: no-border;
	background-color: #e6ebf0;
}

.form-active {
	border: none !important;
	background-color: #e6ebf0 !important;
	font-size: 16px;
	color: #52575a !important;
	border-radius: 0px;
}

.form-active:focus {
	outline: none !important;
}

.form-inactive:focus {
	outline: none !important;
}

.form-inactive {
	border: none !important;
	background-color: #ffffff !important;
	font-size: 16px;
	color: #52575a !important;
	border-radius: 0px;
}

.taskModules-label {
	font-size: 12px;
}

.solvedForm {
	color: #c9c9c9c9 !important;
}

.imageSize-danger-icon {
	height: 15px;
}

.AgentCancel-comments-table {
	margin-top: -20px;
	width: 100%;
	height: 180px;
	overflow-y: scroll;
	scrollbar-width: none;
}

.textFormat-taskDetail-labels {
	font-family: 'Lato';
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #0a0a0a;
}

.textFormat-taskDetail-labels-2 {
	font-family: 'Lato';
	font-size: 19px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #0a0a0a;
}

.textFormat-taskDetail-info {
	font-family: 'Lato';
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #0a0a0a;
}

.textFormat-taskDetail-info-2 {
	font-family: 'Lato';
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #0a0a0a;
}

.row-info-height {
	height: 20px;
}

.font-task-document {
	font-family: 'Lato';
	font-size: 18px;
	font-weight: bold;
}

.sizeImageSourceDetail {
	width: 40px;
	height: 46px;
}

.sizeIconDocumentDownload {
	width: 40px !important;
	height: 25px !important;
}

.paperSize {
	height: 70px !important;
}

.buttonUpdateLinkSise {
	width: 100px;
	height: 55px;
}

.buttonSizePage {
	color: darkgray !important;
	cursor: pointer !important;
}

.document-text-overFlow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 400px;
}

.document-task-gallery {
	cursor: default !important;
}

.document-task-gallery-pointer {
	cursor: pointer !important;
}

.task-activity-time-col {
	white-space: nowrap;
	width: 160px;
	padding: 8px;
}

.task-activity-date-col {
	white-space: nowrap;
	width: 180px;
	padding: 8px;
}
