.marginDeleteBtn{
    margin-left: 1000px !important;
    position: relative;
  }

.form-txtQuestion{
    margin-left: 0%;
    font-size: 13px;  
}

  
  .margin-form{
    margin-left: 2%;
    margin-bottom: 0%;
  }
  
  .margin-btnshowTable{
    background-color:transparent;
    margin-left: auto;
    border: none!important
  }
  .btnShowTable:focus, .btnShowTable.focus {
    box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
  }
  
  .margin-btnSave{
    margin-right: 0%;
  }


  .btnSave{
  
    text-align: center;
    width: 200px;
    height: 40px;
    border-radius: 6px;
  }