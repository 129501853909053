/*+++++++++++++++++++++++++++++++*/
.drag-target {
	border-right: 2px solid #ccc;
}

.drag-selection {
	opacity: 0.3;
}

#title {
	text-align: center;
	font-family: arial, sans-serif;
}

.SmartTable-font {
	font-family: 'Lato' !important;
	font-size: 12px !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: center;
	color: #52575a;
}

.react-grid-HeaderCell,
.react-grid-Cell {
	box-sizing: border-box;
	border: 1px solid #e3e4e5 !important;
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	font-size: 12px;
}

.react-grid-Header {
	background: none !important;
}

.react-grid-HeaderRow {
	background: white !important;
	color: gray !important;
}
.react-grid-HeaderCell {
	background: white !important;
	color: #c0c2c4 !important;
}

.pull-right {
	margin-right: 15px;
}

.task-status {
	background-color: #5e9de6;
	color: white;
	font-weight: bold;
	padding: 7px;
	width: 100%;
	height: 50px;
	font-size: 11px;
	text-align: center !important;
	border-radius: 30px;
	white-space: pre-wrap; /* CSS3 */
	white-space: -moz-pre-wrap; /* Firefox */
	white-space: -pre-wrap; /* Opera <7 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word;
}

.task-view {
	background-color: transparent !important;
	color: transparent !important;
	border-color: transparent !important;
}

.radio-custom-label {
	width: 10px !important;
}

.react-grid-checkbox:checked + .react-grid-checkbox-label:before {
	background: #5e9de6 !important;
	box-shadow: inset 0px 0px 0px 0px #fff !important;
}

.react-grid-checkbox-label {
	margin-bottom: 0px;
}

.react-grid-checkbox + .react-grid-checkbox-label:before,
.radio-custom + .radio-custom-label:before {
	content: '';
	background: #fff;
	border: 1px solid #ddd !important;
	display: inline-block;
	vertical-align: middle;
	width: 15px !important;
	height: 15px !important;
	border-radius: 5px !important;
	text-align: center;
	margin-bottom: 0px;
}

.fielder-table-container {
	width: 90%;
	margin-left: 5%;
	margin-right: 5%;
}

.fielder-table-pagination {
	margin: 20px 0px;
}

.fielder-table-pagination .range-select {
	display: inline-block;
	float: left;
	width: 100px;
}
.fielder-table-pagination .range-label {
	float: left;
	width: 45px;
	min-height: 38px;
	display: flex;
	justify-content: center;
	/* align horizontal */
	align-items: center;
}

.table-smart-table-margin {
	max-width: 100% !important;
}

.SmartTable-circle-texture {
	width: 15px;
	height: 15px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.react-grid-Main {
	height: 100% !important;
}

.react-grid-Container {
	height: 100% !important;
	width: 100% !important;
}

.react-grid-Grid {
	min-height: 100vh !important;
}

.react-grid-Canvas:hover::-webkit-scrollbar {
	width: 7px;
}
.react-grid-Canvas:hover::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.react-grid-Canvas {
	height: 100% !important;
}

.SmartTable-icon-image {
	width: 60%;
	margin-left: 20%;
}

.Toastify__toast-container {
	width: 650px !important;
}
.toast-font-style {
	font-family: 'Lato';

	font-size: 12px;

	font-weight: normal;

	font-style: normal;

	font-stretch: normal;

	line-height: 1.19;

	letter-spacing: normal;

	text-align: left;

	color: #52575a;
}

.boldText {
	font-weight: bold;
}

.Toastify__toast-container--bottom-center {
	left: 40% !important;
}

.react-grid-HeaderCell__draggable {
	position: absolute;
	top: 5px !important;
	height: 50% !important;
	border-right: 1px solid #e6ebf0;
}

.rdg-last--frozen {
	box-shadow: none !important;
}

.react-grid-HeaderCell--frozen:last-of-type {
	box-shadow: none !important;
}

.MuiSwitch-root {
	width: 69px !important;
	height: 47px !important;
	padding: 10px !important;
	margin: -10px 0px;
}

.MuiSwitch-switchBase {
	top: 5px !important;
	left: 4px !important;
}

.MuiSwitch-track {
	border-radius: 15px !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
	color: #fff !important;
}

.MuiSwitch-switchBase.Mui-checked {
	transform: translateX(58%) !important;
}

.height-row {
	height: 45px;
}

.SmartTable-icon-image-info {
	width: 130%;
	margin-left: 20%;
	margin-top: 30%;
}

.SmartTable-more-icon-info {
	width: 18px;
}

.scaleGreyColor {
	filter: grayscale(1) saturate(0) contrast(0.7) brightness(3.4) !important;
}


.tooltip-text-format {
	white-space: nowrap;       
  	overflow: hidden;          
  	text-overflow: ellipsis;   
  	width: 200px;  
}
