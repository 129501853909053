.success-message-center-icon{
    
        margin: auto;
}

.success-message-margin{
    margin-bottom: 3px;
}


.success-message-btn-margin{
    margin: auto;
}

.success-message-modal-body{

    padding: 0rem !important;
}

.modal-footer{
    border-top: 0px !important;
}