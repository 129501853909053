.Add-branch-add-icon-position {
	position: relative !important;
	top: 12.6px !important;
	margin-left: 25px !important;
}

.Add-branch-text-title-position {
	margin-top: 10px;
}

.Add-branch-office-btn-center {
	margin: auto;
}

.Add-branch-office-btn-size {
	width: 271px !important;
	height: 56px !important;
}

.coodinates-input {
	border-radius: 4px;
	border: 1px solid grey;
}

.id-date-from {
	background-color: white !important;
}

.id-date-to {
	background-color: white !important;
}

.pac-container {
	z-index: 99999 !important;
}

.branch-newLocation {
	background: #8d909417;
	padding-top: 5px;
	padding-bottom: 5px;
}

.branch-label-input {
	font-family: 'Lato';
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 3;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.CreateTask-nameContainer-icon {
	position: absolute;
	left: 98%;
	top: 25%;
	height: 20px;
	display: none;
}

.CreateTask-idCustom-icon {
	position: absolute;
	left: 101%;
	top: 25%;
	height: 20px;
	display: none;
}

.image-coordinates-icon {
	position: absolute;
	left: 93%;
	top: 93%;
	height: 20px;
	display: none;
}

.active-title {
	font-family: 'Lato';
	font-size: 16px;
	line-height: 1.19;
	letter-spacing: 0.7px;
	color: #1b253a;
}

.CreateTask-dir-desc-fieldContainer-icon {
	position: absolute;
	left: 101%;
	top: 55%;
	height: 20px;
	display: none;
}

.branch-switch {
	font-size: 14px;
}
