.Loading {
	width: 100vw !important;
	height: 100% !important;
	background-color: rgba(255, 255, 255, 0.5);
	position: fixed;
	display: inline-flex !important;
	z-index: 2000;
	overflow: hidden i !important;
}

.Loading.Loading-with-navigation {
	z-index: 1000 !important;
}

.Loading-icon {
	width: 70% !important;
	margin-left: 15% !important;
	margin-right: 15% !important;
}

.Loading-text {
	width: 100% !important;
	padding-left: 35% !important;
	padding-right: 35% !important;
	padding-top: 3%;
	text-align: center;
	font-weight: 500;
	font-size: 20px;
}

.Loading-text span {
	width: 100% !important;
}

.progress-bar.active,
.progress.active .progress-bar {
	animation: progress-bar-stripes 0.7s linear infinite;
}

.progress-bar {
	background-color: #d33b6e !important;
}

.progress {
	height: 41px !important ;
}

element {
	width: 36.5112%;
}

.progress-bar.active,
.progress.active .progress-bar {
	animation: progress-bar-stripes 0.5s linear infinite !important;
}

.progress-bar-striped {
	background-size: 1rem 1rem !important;
}

.percentageFontSize {
	font-family: 'Lato';
	font-size: 30px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: var(--dark-grey-blue);
}

.floatLoading {
	transition: bottom 0.3s ease;
	width: 100vw !important;
	position: fixed;
	z-index: 99;
	bottom: 0;
	opacity: 1 !important;
	background: white;
	background-color: rgba(255, 255, 255, 0.8);
}

.transparentSpan {
	background: white;
}
.floatLoadingContainer {
	background: white;
	z-index: 999999999999999;
}

.heightprogress {
	height: 30px !important;
}
