.custom-file-update {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.task-upload-btn-wrapper input[type='file'] {
	display: none !important;
}
