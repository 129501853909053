.Create-client-add-icon-size{
    width: 120.4px;
    height: 79.9px;
    object-fit: contain;

}

.Create-client-margin-txt-title{

    margin-left: 10%;

}
.create-client-popUp-dropdown-arrow .dropdown-toggle::after {
    margin-left: 150px; 
  }

  .create-client-btnSave-margin{
    margin-top: 30% !important; 
    margin-left: 30%!important;
  }

  .create-client-modal-btnsave-size{
    width: 271px;
    height: 56px;
    border-radius: 6px;
  }

 .infoNewModules-icon.size{
    width: 22.1px;
    height: 22.7px;

 }


 .infoNewModules-padding{
    padding-left:0px !important 
 }


 .infoNewModules-padding-right{
    padding-right:0px !important 
 }

 .row-full-width{
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
 }

 .infoNewModules-margin-top {
    margin-top: 7.5vh;
 }

 .card-nomargin {
    border:none !important;
 }

 .infoMofules-buttom-size{
     width: 20% !important
 }

 .btn.focus, .btn:focus {
   box-shadow: none!important;
}

.infoNewModules-number-circle {
   border-radius: 50%;
   width: 25px;
   height: 25px;
   padding: 2px;
   color: white;
   padding-left: 1px;
   background-color: #dc5c87;
   text-align: center;
   font-size: 14px;
}

.infoModules-advice {
   font-size: 12px;
}

.infoNewModules-border {
   border-radius: 6px;
   border: solid 1px #e6ebf0;
   cursor: pointer;
}

.infoNewModules-alert-color {
   color: #dc5c87;
}