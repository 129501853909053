.status-square {
	position: relative;
	width: 80% !important;
	border-radius: 7px;
	padding-top: 5%;
	padding-bottom: 5%;
	justify-content: center;
	cursor: default;
}

.status-text {
	margin: auto !important;
	text-align: center;
}

.status-Fielder-task-managing {
	/*0*/
	background-color: #63a1e781;
	color: #0f5099;
}

.status-Fielder-task-waiting-for-agent {
	/*3*/
	background-color: #86ebd460;
	color: #20ad8f;
}

.status-Fielder-task-in-progress {
	/*4*/
	background-color: #1b7adf70;
	color: #065ebd;
}

.status-Fielder-task-executing {
	/*5*/
	background-color: #0980cf6b;
	color: #0775be;
}

.status-Fielder-task-finish {
	/*2*/
	background-color: #3ad38e79;
	color: #137c4d;
}

.status-Fielder-task-cancel {
	/*1*/
	background-color: #e9343469;
	color: #c21010;
}

.status-Fielder-task-start-route {
	/*6*/
	background-color: #44b2dda1;
	color: #067ead;
}

.status-Fielder-task-cancel-by-partner {
	/*7*/
	background-color: #e62c2c79;
	color: #8f1a1a;
}

.status-Fielder-task-assigned_to_partner {
	/*8*/
	background-color: #a1dbaa9f;
	color: #07921c;
}

.status-Fielder-task-assigned-to-team {
	/*9*/
	background-color: #a1dbaa91;
	color: #54ad62;
}

.status-Fielder-task-arrived {
	/*10*/
	background-color: #fff185d5;
	color: #c59c2b;
}

.status-Fielder-task-deleted {
	/*11*/
	background-color: #c0bdbd;
	color: #3b3434;
}

.status-Fielder-task-rejected {
	/*11*/
	background-color: #ef5a6473;
	color: #f93b48;
}

.status-Fielder-task-arrive {
	/*11*/
	background-color: #dce4009c;
	color: #dce400;
}

.status-Fielder-task-new {
	/*11*/
	background-color: #b7e8fc9c;
	color: #b7e9fc;
}

.status-Fielder-task-paused-failed {
	background: #ff8000;
	color: #ff8000;
}

.status-Fielder-task-autoassigning {
	background: #b7e9fc;
	color: #2489b1;
}

.status-Fielder-task-autoassigning-success {
	background: #a1dbaa;
}

.status-Fielder-task-autoassigning-failed {
	background-color: #e9343469;
	color: #c21010;
}

.status-Fielder-task-paused {
	background: #f1c676d8;
	color: #c5740a;
}
