.Create-client-add-icon-size {
	width: 120.4px;
	height: 79.9px;
	object-fit: contain;
}

.Create-client-margin-txt-title {
	margin-left: 10%;
}

.create-client-popUp-dropdown-arrow .dropdown-toggle::after {
	margin-left: 150px;
}

.create-client-btnSave-margin {
	margin-top: 30% !important;
	margin-left: 30% !important;
}

.create-client-modal-btnsave-size {
	width: 271px;
	height: 56px;
	border-radius: 6px;
}

.no-padding-bottom {
	padding-bottom: 0% !important;
}

.modal-body {
	padding-top: 0% !important;
}

.Fielder-display-modal {
	opacity: 1 !important;
}

.Fielder-no-display-modal {
	opacity: 0 !important;
}
