.RecoverPassword {
	width: 40vw;
	height: 90vh;
	border-radius: 6px;
	box-shadow: 0 2px 20px 0 rgba(31, 31, 31, 0.04);
	background-color: #ffffff;
}

.Login-img {
	width: 232.5px;
	height: 65.9px;
	object-fit: contain;
}

.RecoverPassword-label {
	font-family: 'Lato';
	font-size: 1.6vw;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: center;
	color: #354052;
	margin: 10vh 0vw 5vh 0vw;
}

.RecoverPassword-input {
	margin: 15vh 3vw 0px 3vw;
}

.RecoverPassword-button {
	margin: 12vh 0vw 8vh 0vw;
}

.RecoverPassword-ref {
	font-size: 1.2vw;
	margin: 100vh 0vw 0vw 0vw !important;
}

.RecoverPassword-label2 {
	font-width: 1030vw;
	font-height: 5vh;
	opacity: 0.5;
	font-family: SourceSansPro;
	font-size: 1vw;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.58;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
	margin: 13vh 4vw 0vh 4vw;
}
