.react-chatbot-kit-chat-container {
    width: 100%;
    height: calc(100vh - 143px);
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 734px) {
    .react-chatbot-kit-chat-container {
        height: calc(100vh - 143px);
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 840px) {
    .react-chatbot-kit-chat-container {
        height: calc(100vh - 75px);
    }
}

.react-chatbot-kit-chat-inner-container {
    height: 100%;
}

.react-chatbot-kit-user-chat-message{
    margin-right: 10px;
    width: 100%;
}
.react-chatbot-kit-chat-bot-message{
    margin-left: 10px;
    width: 100%;
    white-space: pre-line;
}

.react-chatbot-kit-chat-message-container {
    height: 100%;
    padding-bottom: 20%;
}

.react-chatbot-kit-chat-input {
    font-size: 0.80rem;
}