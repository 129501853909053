.amcharts-amexport-item {
	background-color: #fff!important;
	opacity: 1!important;
	box-shadow: -3px 10px 43px -26px rgba(0,0,0,0.75);
}

.amcharts-amexport-item:hover {
	background-color: #f5f7fa!important;
}
  
.amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    top: -3px!important;
	left: 2px;
	background-color: #fff!important;
	opacity: 1!important;
	box-shadow: -3px 10px 43px -26px rgba(0,0,0,0.75);
}