@import url('https://fonts.googleapis.com/css?family=Lato');

.Register input {
	text-align: left;
}

.Register-title {
	font-family: 'Lato';
	margin-top: 70px;
	font-size: 23px;
	color: #354052;
}

.Register-fieldContainer-icon {
	position: absolute;
	left: 104%;
	top: 45%;
	height: 20px;
	display: none;
}

.Register-fieldContainer-specify-icon {
	position: absolute;
	left: 104%;
	top: 30%;
	height: 20px;
	display: none;
}

.Register-field-phone-icon {
	position: absolute;
	left: 102%;
	top: 45%;
	height: 20px;
	display: none;
}

.Register-field-icon {
	position: absolute;
	left: 103%;
	top: 25%;
	height: 20px;
	display: none;
}

.Register-form-hint {
	text-align: center;
	color: #dc5c87;
	font-family: 'Lato';
	display: none;
}

.Register-fieldContainer {
	position: relative;
}

.Register .form-group input {
	opacity: 1;
}

.Register .form-group select {
	opacity: 1;
}

.Register-info {
	margin-top: 80px;
	position: relative;
}

.Register-info-text {
	color: #8b8d91;
	font-size: 12px;
}

.Register-info-img {
	position: absolute;
	margin-top: 5px;
	margin-left: 15px;
}

.Register-ref {
	margin: 8vh 0vw 0vw 0vw;
}

.react-phone-number-input__country-select {
	opacity: 0 !important;
}


.RegisterButton {
	height: '80px !important'
}