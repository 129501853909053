.custom-file-upload {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.task-upload-btn-wrapper input[type='file'] {
	display: none !important;
}

.upload-btnsave-size {
	width: 120;
	height: 40;
	border-radius: 6px;
}
