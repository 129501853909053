.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid green; /* Borde verde por defecto */
}

/* Estilos para el checkbox marcado */
.custom-checkbox input:checked ~ .checkmark {
    background-color: green; /* Fondo verde cuando está marcado */
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}