@import url('https://fonts.googleapis.com/css?family=Lato');

.Dashboard {
	background-color: #f5f7fa;
	height: 100%;
	margin: 0;
	overflow: auto;
	margin-bottom: 20px;
}

.Dashboard-margin {
	margin-left: 5rem !important;
}

.Dashboard-box {
	box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
	background-color: white;
	font-family: 'Lato';
}

.Dashboard-box-content {
	padding-left: 12px;
	padding-right: 20px;
}

.Dashboard-box-content-graph {
	height: 500px;
}

.Dashboard-title {
	font-family: 'Lato';
	font-weight: 50px;
	padding-left: 10px;
	font-size: 18px;
	text-align: left;
	color: #1b253a;
	letter-spacing: 1.4px;
}

.Dashboard-box-title {
	padding-left: 10px;
	font-size: 17px;
	text-align: left;
	line-height: 75px;
	color: #1b253a;
	font-weight: 400;
	letter-spacing: 0.7px;
	display: inline;
}

.textTotalCount-graph {
	font-size: 17px;
	color: #1b253a;
}

.textTotalCount-Month {
	font-size: 17px;
	font-weight: bold;
	color: #1b253a;
}

.Dashboard-Map {
	height: 385px;
	text-align: center;
	line-height: 75px;
	font-size: 30px;
}

.Dashboard-tasksBrief {
	color: #1b253a;
	height: 848px !important;
}

.Dashboard-tasksBrief-open {
	color: #1b253a;
	height: 1433px !important;
}

.Dashboard-tasksBrief-agent {
	height: 415px;
	color: #1b253a;
}
.box-shadow-color {
	box-shadow: 0 0 3px #dc5c87 !important ;
}

.Dashboard-tasksBrief-table-sla {
	margin-top: -20px;
	width: 100%;
	height: 250px;
	overflow-y: scroll;
	scrollbar-width: none;
}

.Dashboard-tasksBrief-table-sla th {
	vertical-align: middle !important;
}

.Dashboard-tasksBrief-table-agent {
	margin-top: -20px;
	width: 100%;
	height: 600px;
	overflow-y: scroll;
	scrollbar-width: none;
}

.Dashboard-tasksBrief-table {
	margin-top: -20px;
	width: 100%;
	height: 585px;
	overflow-y: scroll;
	scrollbar-width: none;
}
::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}
.Dashboard-tasksBrief-table table {
	width: 100%;
	height: 100%;
	border-collapse: collapse;
}

.Dashboard-tasksBrief-table td,
th {
	text-align: center;

	padding-top: 8px;
}

.Dashboard-tasksBrief-table th {
	position: sticky;
	top: 0;
	background-color: #ffffff;
	font-size: 14px;
	padding-bottom: 5px;
	color: #8a8383;
	vertical-align: middle !important;
}

.Dashboard-tasksBrief-table td {
	color: #1b253a;
}

.agentHeader-zindex {
	z-index: 5;
	text-align: start;
}

.agent-table-btn-size {
	height: 30px;
	width: 120px;
}

.Dashboard-tasksCompleted {
	height: 355px;
}

.Dashboard-tasksCompleted-graph {
	/* height: 100%; */
	height: 240px;
	margin-top: -20px;
}

.Dashboard-tasksMonth {
	height: 200px;
}

.Dashboard-tasksMonth-table {
	table-layout: auto;
	color: #1b253a;
	width: 75%;
	height: 50%;
	margin: 0 auto;
}

.Dashboard-tasksMonth-table th {
	font-size: 38px;
	line-height: 35px;
	border-bottom: 5px solid #d0021b;
	font-weight: 500;
}

.Dashboard-tasksMonth-table td {
	text-align: right;
	font-size: 16px;
	font-weight: 400;
}

.Dashboard-agentsTotal {
	height: 370px;
	overflow-x: scroll;
	scrollbar-width: none;
}

.Dashboard-taskCompanyBranchGraph {
	height: 630px !important;
	overflow-x: none;
	scrollbar-width: none !important;
}

.Dashboard-taskCompanyBranchGraph-Client {
	height: 846px !important;
	overflow-x: none;
	scrollbar-width: none !important;
}

.Dashboard-taskSlaCompanyBranch {
	height: 370px;
	overflow-x: none;
	scrollbar-width: none;
}

.Dashboard-taskSlaCompanyBranch-open {
	height: 418px;
	overflow-x: none;
	scrollbar-width: none;
}

.Dashboard-agentsTotal-table {
	table-layout: auto;
	color: #354052;
	/* width: 180px;  */
	width: 100%;
	margin: 0 auto;
	margin-top: -10px;
	text-align: center;
	border-collapse: collapse;
}

.Dashboard-agentsTotal-table th {
	font-size: 30px;
}

.Dashboard-agentsTotal-table td {
	text-align: center;
	font-weight: 400;
}

.Dashboard-agentsTotal-table-firstRow {
	border-bottom: 2px solid #979797;
	color: #354052;
	padding-left: 35px;
	padding-right: 35px;
	border-left: 0px;
	border-right: 0px;
	font-size: 20px;
	font-weight: bold;
}

.Dashboard-agentsTotal-table-secondRow {
	font-size: 40px;
	font-weight: 500;
}

.Dashboard-agentsTotal-table-secondRow div {
	display: inline-block;
}

.Dashboard-agentsTotal-table-secondRow-agentsActive {
	border-bottom: 3px solid #0ecf04;
}

.Dashboard-clientSatisfaction {
	height: 165px;
}

.Dashboard-clientSatisfaction-rating {
	unicode-bidi: bidi-override;
	direction: rtl;
	margin-top: -25px;
	margin-left: 10px;
	text-align: left;
	color: #ffda44;
	font-size: 50px;
}

.Dashboard-clientSatisfaction-rating > span {
	display: inline-block;
	position: relative;
	width: 1.1em;
}

.Dashboard-clientSatisfaction-rating > .Dashboard-clientSatisfaction-rating-star:before,
.Dashboard-clientSatisfaction-rating > .Dashboard-clientSatisfaction-rating-star ~ span:before {
	content: '\2605';
	position: absolute;
	color: #ffda44;
}

.Dashboard-tasksStatus {
	height: 165px;
}

.Dashboard-tasksStatus-table {
	margin-top: -20px;
	text-align: center;
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
}

.Dashboard-tasksStatus-table th {
	font-size: 40px;
	border: 2px solid #979797;
	padding-top: 0;
}

.Dashboard-tasksStatus-table td {
	font-weight: 400;
	font-size: 14px;
}

.Dashboard-tasksStatus-table tr:first-child th {
	border-top: 0;
	border-bottom: 0;
}

.Dashboard-tasksStatus-table tr th:first-child {
	border-left: 0;
}

.Dashboard-tasksStatus-table tr:last-child th {
	border-bottom: 0;
}

.Dashboard-tasksStatus-table tr th:last-child {
	border-right: 0;
}

.Dashboard-button-agents-style {
	border: none !important;
	background: transparent !important;
	font-family: 'Lato';
	font-size: 54px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #1b253a;
}

.Dashboard-show-button-agents-style {
	width: 56px;
	height: 19px;
	border-radius: 5px;
	border: solid 1px #ca6486;
	font-size: 12px;
	color: #dc5c87;
}

.Dashboard-show-button-agents-style:hover {
	background-color: #dc5c87;
	color: #ffffff;
}

.Dashboard-save-button-filter {
	width: 80px;
	height: 38px;
	border-radius: 5px;
	border: solid 1px #ca6486;
	font-size: 12px;
	color: #dc5c87;
}

.Dashboard-save-button-filter:hover {
	background-color: #dc5c87;
	color: #ffffff;
}

.Dashboard-button-agents-name-style {
	border: none !important;
	background: transparent !important;
	font-family: 'Lato';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #1b253a;
}

.cssLocationTaskResume {
	position: absolute;

	left: 37%;

	top: 265%;
}
.amcharts-amexport-item.amcharts-amexport-item-level-0 > a {
	margin-top: -40px;
}

.number-agent-size {
	font-size: 45px;
}

.agent-table-pointer {
	cursor: pointer;
}

.marginLoadingTask {
	margin-top: 25% !important;
}

.marginLoadingTaskAgent {
	margin-top: 7% !important;
}

.marginLoadingGraphTask {
	margin-top: 0% !important;
}

.marginLoadingTaskBrief {
	margin-top: 20% !important;
}

.marginLoadingTaskBriefSla {
	margin-top: 10% !important;
}

.marginLoadingTaskMini {
	margin-top: 0% !important;
}

.select-size-dashboard {
	height: 30px !important;
	width: 120px !important;
	font-size: 12px !important;
}

.show-cursor {
	cursor: pointer;
}

.align-table {
	vertical-align: middle !important;
}

.color-text-alert-month {
	color: #dc5c87 !important;
}
