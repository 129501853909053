.confirmation-message-ceter-icon{
    margin: auto;
}

.confirmation-message-margin{
    margin-bottom: 3px;
}

.confirmation-message-btn-margin{
    margin: auto;
}

.confirmation-message-modal-body{
    padding: 0rem !important;
    margin-left: 30px;
    margin-right: 30px;
}

.confirmation-alert-message {
    font-size: 14px;
    color: #dc5c87;
}

.delete-confirmation-alert-message{
    font-size: 11px;
    text-align: justify !important;
}


.confirmation-cancel-btn{
    color: #6c6c6c !important;
    text-decoration: underline;
}
.modal-footer{
    border-top: 0px !important;
}

.modal-shadow .modal-backdrop{
    z-index: 1050 !important;
}
