.EditCreateDetail-agent-card-margin-input-label {
	margin-left: 14px;
	min-width: 25%;
	max-width: 300px;
}

.EditCreateDetail-agent-cards-btnsave-margin {
	margin-top: 20% !important;
	margin-left: 10% !important;
}

.Agents {
	width: 100vw;
	overflow-x: hidden;
}

.AgentIcon {
	width: 23px;
	color: black;
}

.Agent-disabledInput {
	border-color: white;
}

.EditCreateDetail-container {
}

.Agents-background {
	background-color: #f8f9fa;
	height: calc(100% + 30px);
}

.EditCreateDetail-header {
	padding-right: 20px;
	padding-left: 20px;
}

.Agent-fieldContainer {
	position: relative;
}

.Agent-fieldContainer-icon {
	display: inline-block;
}

.Agent-profile-picture {
	width: 100%;
}
.Agent-txt-label-input {
	color: #354052;
	font-size: 16px;
}

.Agent-body-row-radiobox-container {
	padding-top: 18px;
	border: 2px solid #e6ebf0;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
}

.Agent-options-list-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 15px;
}

.Agent-options-list-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	margin-left: 30px;
}

.Agent-options-list-container-checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: white;
	border: solid 1px #e6ebf0;
}

.Agent-options-list-container:hover input ~ .Agent-options-list-container-checkmark {
	background-color: #fcd6e8;
}

.Agent-options-list-container input:checked ~ .Agent-options-list-container-checkmark {
	background-color: #dc5c87;
	outline: #fcd6e8 solid 5px;
}

.Agent-btn-container {
	display: flex;
	justify-content: center;
}

.Agent-addAgent-btn {
	background: none;
	border: none;
	padding: 0;
	display: inline-block;
	color: #94979b;
	padding: 10px;
}

.Agent-addAgent-btn-img {
	float: left;
	margin-right: 0.5em;
	margin-top: 30px;
}

.Agent-fieldContainer {
	position: relative;
}

.Agent-title-flex {
	display: flex;
	flex-direction: row;
}

.Agent-fieldContainer-edit-btn {
	margin-left: 10px;
	background: none;
	border: none;

	/* position: absolute;
    left: 40%;
    margin-top: 10px;
    padding-top: 10px; */
}

.Fielder-modals-create-edit-detail-header-txt-title-ag {
	padding-left: 0px;
	margin-top: 10px;
}

.Agent-fieldContainer-icon {
	display: none;
}
.Agent-fieldContainer-icon-esp {
	position: absolute;
	left: calc(90% + 15px);
	padding-top: 7px;
	display: none;
}

.Agent-fieldContainer-input {
	position: relative;
}
.Agent-input-help {
	color: #dc5c87;
	margin-bottom: -15px;
	font-size: 13px;
}

.Agent-schedule-alert-icon {
	display: none;
}

.button-class {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

.scroll-modal-contaner {
	overflow-y: scroll;
	overflow-x: hidden;
}

.input-phone-radios {
	border-radius: 4px !important;
}

.sizeColumnConfig {
	width: 202px !important;
}

.sizePhone {
	height: 110px !important;
	width: auto !important;
}
