.my-modal .modal-content {
	height: auto !important;
	min-height: 100% !important;
	border-radius: 0 !important;
}

.taskinfo-scrollTextarea {
	overflow-y: scroll;
	height: 90px;
	resize: none;
}

.taskAgent-color {
	background-color: #8d909417 !important;
}

.edit-cursor-pointer {
	cursor: pointer;
}

.gallery-text {
	font-weight: bold;
}

.photoMessagePermission {
	color: #db2059 !important;
}

.boderColorImage {
	border-color: #db2059 !important;
	cursor: pointer !important;
}

.pointerImageGallery {
	cursor: pointer !important;
}

.buttonUpdateFileSise {
	width: 90px;
	height: 45px;
}
