.no-border {
	border: none !important;
}

.small-title {
	font-size: 16px !important;
	color: #354052;
	font-weight: bold;
}

.icon {
	width: 20px;
	height: 20px;
}

.ps {
	height: 150px;
}

.ps2 {
	height: 180px;
}

.sm {
	height: 100px;
}

.md {
	height: 300px;
}

.lg {
	height: 500px;
}

.popup-required-fields {
	width: 100%;
}

.popup-footer-div {
	width: 100%;
}

.popup-footer-btn {
	display: flex !important;
	align-items: center;
	justify-content: center;
}
