.error-message-center-icon{
    
        margin: auto;
}

.error-message-margin{
    margin-bottom: 3px;
}


.error-message-btn-margin{
    margin: auto;
}

.error-message-modal-body{

    padding: 0rem !important;
}

.modal-footer{
    border-top: 0px !important;
}

.modal-footer-custom{
    justify-content: center !important;
}