.fielder-advancedfiltermenu {
	font-size: 15px;
	z-index: 110;
}

.fielder-avenced-filter {
	width: 720px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border-radius: 7px;
	padding: 7px;
	z-index: 110;
	background-color: white;
	position:absolute
}

.fielder-dropdownmenu-list-item {
	width: 300px;
	height: 30px;
	margin-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	z-index: 111;
}
.fielder-clear-button {
	background: none;
	border: none;
}

.fielder-button-filter-apply-size {
	width: 150;
	height: 35px;
	border-radius: 6px;
}

.fielder-button-filter-size {
	width: 150;
	height: 35px;
	border-radius: 6px;
	font-size: 9px;
}

.image-pointer {
	cursor: pointer !important;
}

.solid-line {
	border-top: 3px solid #bbb;
	border-style: outset;
}

.font-sizeAvancedFilter {
	font-size: 15px !important;
}
