html {
	overflow-y: scroll;
}
body::-webkit-scrollbar {
	width: 7px !important;
	color: rgba(0, 0, 0, 0.5) !important;
}
body::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

::-webkit-scrollbar {
	width: 7px !important;
	color: rgba(0, 0, 0, 0.5) !important;
}

.Fielder-conteiner {
	max-width: 100% !important;
}

.Fielder-body {
	background: #f5f7fa;
	max-width: 100% !important;
	padding: 0;
}

.fielder-globalaccount-body {
	background: #f4f6f9;
	min-height: 100vh;
	height: auto;
}

.Fielder-ultra-wide {
	max-width: 1600px !important;
}

.Fielder-TopMenu-icon {
	color: #505c6e;
}

.Fielder-TopMenu-icon-active {
	color: #dc5c87;
}

.fielder-button {
	background: #f5e6ed !important;
	border: #f5e6ed !important;
	color: #dc5c87 !important;
	font-size: 16px;
	border-radius: 6px !important;
	width: 100% !important;
	height: 60px;
	text-align: center;
	transition: 0.3s;
}

.fielder-button:active {
	background: #dc5c87 !important;
	border: #dc5c87 !important;
	color: #f5e6ed !important;
}

.fielder-button:hover {
	background: #dc5c87 !important;
	border: #dc5c87 !important;
	color: #f5e6ed !important;
}

.Fielder-button-task {
	background: #f5e6ed !important;
	border: #f5e6ed !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 100%;
	height: 41px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
}

.Fielder-button-download {
	background: #f5e6ed !important;
	border: #f5e6ed !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 100%;
	height: 34px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
}

.Fielder-button {
	background: #f5e6ed !important;
	border: #f5e6ed !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 50%;
	height: 60px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
}

.Fielder-button-disabled {
	background: #f5e6ed !important;
	border: #f5e6ed !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 50%;
	height: 60px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
	opacity: 0.4 !important;
}

.Fielder-button:active {
	background: #dc5c87 !important;
	border: #dc5c87 !important;
	color: #f5e6ed !important;
}

.Fielder-button:hover {
	background: #dc5c87 !important;
	border: #dc5c87 !important;
	color: #f5e6ed !important;
}

.Fielder-button-no-background {
	line-height: 1.19;
	text-align: center;
	vertical-align: middle !important;
	background: none !important;
	border: none !important;
}

.Fielder-button-clear {
	background: none !important;
	border: 1px solid #dc5c87 !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 50%;
	margin-left: 25%;
	height: 60px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
}

.Fielder-button-clear:hover {
	background: #dc5c87 !important;
	border: 1px solid #dc5c87 !important;
	color: #fff !important;
}

.fielder-button-clear {
	background: none !important;
	border: 1px solid #dc5c87 !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 100%;
	height: 50px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
}

.fielder-button-clear:hover {
	background: #dc5c87 !important;
	border: 1px solid #dc5c87 !important;
	color: #fff !important;
}

.Fielder-button-clear-disabled {
	background: none !important;
	border: 1px solid #dc5c87 !important;
	color: #dc5c87 !important;
	font-size: 16px;
	width: 50%;
	margin-left: 25%;
	height: 60px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
	opacity: 0.4;
}

.Fielder-item-disabled {
	opacity: 0.4;
}

.Fielder-buttonCancel {
	background: #ffff !important;
	border: 1px solid #c3c6cb !important;
	color: #acaeb1 !important;
	font-size: 16px;
	width: 50%;
	height: 60px;
	border-radius: 6px !important;
	text-align: center;
	transition: 0.3s;
}

.Fielder-buttonCancel:hover {
	background: #f4f5f7 !important;
	border: 1px solid #bbbbbb !important;
	color: #616060 !important;
}

.Fielder-borderOnly-button {
	border-radius: 4px;
	border: solid 1px #e6ebf0;
	background-color: transparent;
	font-size: 16px;
}

.fielder-icon {
	width: 22px !important;
	height: 22px !important;
}

.fielder-icon-button {
	cursor: pointer;
	width: 22px;
	height: 22px;
}

.fielder-pointer-cursor {
	cursor: pointer;
}

.fielder-label-input {
	font-size: 13px !important;
}

.Fielder-input {
	background-color: white !important;
	max-height: 30px;
	height: 70%;
}

.Fielder-icon-button {
	cursor: pointer;
	height: 22px;
	width: 22px;
	margin-right: 5px;
}

.Fielder-none-display {
	display: none;
}

.day-picker-input {
	border: none;
	width: 90%;
	color: #585454;
}

.no-border {
	border: none !important;
}

/* Status Colors */

.waiting-for-agent {
	background: #86ebd4;
}

.in-progress {
	background: #5e9de6;
}

.executing {
	background: #0063ff;
}

.finish {
	background: #00a45b;
}

.cancel {
	background: #f64747;
}

.start-route {
	background: #039289;
}

.cancel-by-partner {
	background: #6d0404;
}

.assigned-to-partner {
	background: #e50082;
}

.assigned-to-team {
	background: #883677;
}

.arrived {
	background: #436f13;
}

.deleted {
	background: #868686;
}

.Fielder-grey-dot {
	border: solid 1px #707070;
	background-color: white;
}

.Fielder-pink-dot {
	background-color: #dc5c87 !important;
}

.Fielder-green-dot {
	background-color: #4caf50 !important;
}

.status-green {
	color: #4caf50;
}
/********************************************************************************************/
/*         Main pages (Agent / Client / Manager / Administrator/ Teams /Partners)          */

.Fielder-mainpage-lefttextusr {
	margin-top: 30px !important;
	font-family: 'Lato' !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.19 !important;
	letter-spacing: 0.7px !important;
	color: #1b253a !important;
}

.Fielder-mainpage-lefttextnumusr {
	margin: auto !important;
	margin-right: 2px !important;
	font-family: 'Lato' !important;
	font-size: 41px !important;
	font-weight: 500 !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.2 !important;
	letter-spacing: normal !important;
	text-align: left !important;
	color: #1b253a !important;
}

.Fielder-mainpage-lefttextnumusr-priority {
	margin: auto !important;
	margin-right: 2px !important;
	font-family: 'Lato' !important;
	font-size: 21px !important;
	font-weight: 500 !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.2 !important;
	letter-spacing: normal !important;
	text-align: left !important;
	color: #1b253a !important;
}

.Fielder-mainpage-txtTitle {
	margin-left: 5% !important;
	margin-top: 30px !important;
	margin-right: 40px !important;
	font-family: 'Lato' !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.19 !important;
	letter-spacing: 0.7px !important;
	text-align: left !important;
	color: #1b253a !important;
}

.Fielder-mainpage-txtTitle-generic {
	margin-left: 2% !important;
	margin-top: 30px !important;
	margin-right: 40px !important;
	font-family: 'Lato' !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.19 !important;
	letter-spacing: 0.7px !important;
	text-align: left !important;
	color: #1b253a !important;
}

.Fielder-mainpage-addtext {
	font-family: 'Lato' !important;
	font-size: 14px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.22 !important;
	letter-spacing: normal !important;
	text-align: left !important;
	color: #94979b !important;
}
.Fielder-mainpage-addtext-color :hover {
	color: #e50082;
}

.Fielder-mainpage-addtext-link-color {
	color: #dc5c87 !important;
}

.Fielder-mainpage-addtext-margin {
	margin-top: 1.1px !important;
}

.Fielder-mainpage-downloadicon {
	margin-top: 26px !important;
	margin-right: 50px !important;
}

.Fielder-mainpage-addicon {
	margin-left: 5px !important;
	margin-top: 0px !important;
}

/*                          Modals ( Create / Edit / Detail /Add)                           */
.Fielder-modals-create-edit-detail-header-border {
	border-bottom: none;
}

.Fielder-modals-create-edit-detail-header-txt-title {
	margin-left: 5%;
	margin-top: 30px;
	margin-right: 40px;

	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: 0.7px;
	text-align: left;
	color: #1b253a;
}

.Fielder-modals-header-border .modal-header {
	border-bottom: 0 none !important;
}

.Fielder-modals-content-border .modal-content {
	border: none !important;
}

.Fielder-modals-footer-border .modal-footer {
	border-top: 0px !important;
}

.Fielder-modal-popUp-image-title {
	position: relative;
	top: 3px;
}

.Fielder-modal-popUp-title {
	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.69;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.Fielder-modal-popUp-title-task-reason {
	font-family: 'Lato';
	font-size: 24px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.69;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.Fielder-popUp-dropdown-arrow-addBranch .dropdown-toggle::after {
	margin-left: 310px;
}

/********************************************************************************************/
/*                                Card (Create / Edit / Detail / Add)                       */

.modal-content {
	border: none;
}
.Fielder-cards-name-text {
	color: #354052 !important;
	font-size: 200% !important;
	letter-spacing: normal;
}

.Fielder-cards-colum {
	min-width: 50%;
}

.Fielder-cards-border {
	border: none !important;
}

.Fielder-cards-profile-picture {
	height: 50px;
	width: 50px;
}

.Fielder-cards-txt-label-input {
	font-family: 'Lato';
	font-size: 9px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 3;
	letter-spacing: normal;
	text-align: left;
	color: #354052;
}

.Fielder-cards-font-inputs {
	font-family: 'Lato' !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-style: normal !important;
	font-stretch: normal !important;
	line-height: 1.69 !important;
	letter-spacing: normal !important;
	text-align: left !important;
	color: #354052 !important;
}

.Fielder-cards-btnsave-margin {
	margin-top: 30% !important;
	margin-left: 30% !important;
}

.Fielder-modal-btnsave-size {
	width: 271px;
	height: 56px;
}

/********************************************************************************************/

/******************************************** Botton ***************************************/

.btn-outline-primary {
	color: #dc5c87 !important;
	border-color: #dc5c87;
	background-color: #f5e6ed !important;
	border-color: #f5e6ed !important;
	text-align: center;
}

.btn-outline-primary:hover {
	background: #dc5c87 !important;
	border: #dc5c87 !important;
	color: #f5e6ed !important;
}

.btn.btn-success:focus,
.btn-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 92, 135);
}

.btn.btn-success:not(:disabled):not(.disabled):active:focus,
.btn.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 92, 135);
}

/*******************************************************************/
/*                             Login                              */

.Fielder-login-topmenu-text {
	width: 55px;
	height: 21px;
	font-family: 'Lato';
	font-size: 17px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.24;
	letter-spacing: normal;
	text-align: left;
	color: #354052 !important;
}
/*********************************    Global form control ******************************************/

.form-control:focus {
	color: #354052 !important;
	opacity: 1 !important;
	border-color: #db3262 !important;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(219, 50, 98) !important;
}

.form-control {
	/*border: 1px solid rgb(151,151,151)!important;*/

	border-radius: 6px !important;
}

/**************************************************************************************************/
/************************************   Dropdown Top Menu *****************************************/
.dropdown-item.active,
.dropdown-item:active {
	color: #ffffff;
	background-color: #db3262;
}

/***********************************************************************************************/
/*********************************** Global Checkbox *************************************************/
.checkbox {
	width: 200px;
	margin-left: 0%;
}
.checkbox .tag {
	color: #354052;
	display: block;
	float: right;
	font-weight: bold;
	position: relative;
	width: 120px;
	padding-left: 10px;
}
.checkbox label {
	display: inline;
}
.checkbox .labelCheckbox {
	display: none;
}
.labelCheckbox + label {
	-webkit-appearance: none;
	background-color: #fafafa;
	border: 2px solid #f5e6ed;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 9px;
	display: inline-block;
	position: relative;
	overflow: hidden;
}
.labelCheckbox:checked + label:after {
	width: 16px;
	height: 16px;
	border-radius: 3px;
	content: '';
	background-color: #dc5c87;
	left: 1px;
	position: absolute;
	top: 1px;

	padding-top: 10px;
}

/***************************************************************************************/
/*                                    Maps                                            */

.Fielder-modal-maps-autocomplete-style {
	/*border: 1px solid rgb(151,151,151);*/
	border-radius: 6px !important;
	border: 1px solid #ced4da;
	height: 34px;
	padding: 10px;
}

.Fielder-modal-maps-autocomplete-style:focus {
	color: #354052 !important;
	opacity: 1 !important;
	border-color: #db3262 !important;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(219, 50, 98) !important;
}
/****************************************************************************************/
/*                                    Field Required                                    */
.Fielder-field-required {
	font-family: 'Lato';
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: center;
	color: #dc5c87;
}
/*****************************************************************************************/
/*                                            Task                                       */
.Fielder-task {
	font-weight: bold;
	padding: 7px;
	border-radius: 30px;
	white-space: pre-wrap; /* CSS3 */
	white-space: -moz-pre-wrap; /* Firefox */
	white-space: -pre-wrap; /* Opera <7 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word;
	font-family: 'Lato';
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.Fielder-task-managing {
	/*0*/
	background-color: #5e9de6;
}

.Fielder-task-waitting-for-agent {
	/*3*/
	background-color: #86ebd4;
}

.Fielder-task-in-progress {
	/*4*/
	background-color: #00438a;
}

.Fielder-task-executing {
	/*5*/
	background-color: #0063a3;
}

.Fielder-task-finish {
	/*2*/
	background-color: #00a45b;
}

.Fielder-task-cancel {
	/*1*/
	background-color: #c40000;
}

.Fielder-task-start-route {
	/*6*/
	background-color: #0099d6;
}

.Fielder-task-cancel-by-partner {
	/*7*/
	background-color: #c40000;
}

.Fielder-task-assigned_to_partner {
	/*8*/
	background-color: #a1dbaa;
}

.Fielder-task-assigned-to-team {
	/*9*/
	background-color: #a1dbaa;
}

.Fielder-task-arrived {
	/*10*/
	background-color: #dce400;
}

.Fielder-task-deleted {
	/*11*/
	background-color: #7d2a37;
}
.Fielder-task-rejected {
	/*11*/
	background-color: #f63f4c;
}

.Fielder-task-arrive {
	/*11*/
	background-color: #dce400;
}

.Fielder-task-new {
	/*11*/
	background-color: #b7e9fc;
}

.Fielder-task-paused-failed {
	background: #ff8000;
}

.Fielder-Preferences-icon {
	width: 20%;
	margin-left: 40%;
	margin-right: 40%;
	height: 100px;
	margin-top: 20px;
}

.Fielder-Preferences-title {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: 0.7px;
	margin-top: 15px;
}

.Fielder-Preferences-container {
	height: auto;
	overflow-y: scroll;
	margin-top: 15px;
	background-color: #fff;
	box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
	padding-bottom: 20px;
}

.Fielder-Preferences-input {
	padding-left: 10% !important;
	padding-right: 10% !important;
	margin-top: 7px;
}

.Fielder-Preferences-checkbox {
	padding-left: 10% !important;
	padding-right: 10% !important;
	margin-top: 7px;
}

.Fielder-Preferences-input label {
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: normal;
	width: 100%;
}

.Fielder-tag {
	display: inline-block;
	margin-bottom: 2px;
}

.rotate-90 {
	transform: rotate(90deg);
}

/*react-daypicker
************
*/

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	margin-left: -8px;
	position: absolute;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	content: '';
	z-index: -1;
	border-width: 8px;
	left: -8px;
	border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	top: 0;
	margin-top: -8px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
	border-top: none;
	border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
	top: -1px;
	border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	bottom: 0;
	margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-bottom: none;
	border-top-color: #fff;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	bottom: -1px;
	border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
}

.react-datepicker {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 0.8rem;
	background-color: #fff;
	color: #000;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	display: inline-block;
	position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
	left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
	border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
	border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
	border-radius: 0.3rem;
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}

.react-datepicker-popper {
	z-index: 1;
}

.react-datepicker-popper[data-placement^='bottom'] {
	margin-top: 10px;
}

.react-datepicker-popper[data-placement='bottom-end'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement='top-end'] .react-datepicker__triangle {
	left: auto;
	right: 50px;
}

.react-datepicker-popper[data-placement^='top'] {
	margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^='right'] {
	margin-left: 8px;
}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
	left: auto;
	right: 42px;
}

.react-datepicker-popper[data-placement^='left'] {
	margin-right: 8px;
}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
	left: 42px;
	right: auto;
}

.react-datepicker__header {
	text-align: center;
	background-color: #f0f0f0;
	border-bottom: 1px solid #aeaeae;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	padding-top: 8px;
	position: relative;
}

.react-datepicker__header--time {
	padding-bottom: 8px;
	padding-left: 5px;
	padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 0.944rem;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	background: none;
	line-height: 1.7rem;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 10px;
	width: 0;
	padding: 0;
	border: 0.45rem solid transparent;
	z-index: 1;
	height: 10px;
	width: 10px;
	text-indent: -999em;
	overflow: hidden;
}

.react-datepicker__navigation--previous {
	left: 10px;
	border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
	border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
	border-right-color: #e6e6e6;
	cursor: default;
}

.react-datepicker__navigation--next {
	right: 10px;
	border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 80px;
}

.react-datepicker__navigation--next:hover {
	border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
	border-left-color: #e6e6e6;
	cursor: default;
}

.react-datepicker__navigation--years {
	position: relative;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__navigation--years-previous {
	top: 4px;
	border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
	border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
	top: -4px;
	border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
	border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
	float: left;
}

.react-datepicker__month {
	margin: 0.4rem;
	text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
	display: inline-block;
	width: 4rem;
	margin: 2px;
}

.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	margin: 5px 0 10px 15px;
	text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
	display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
	display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
	display: inline-block;
	margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
	width: 85px;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time']::-webkit-inner-spin-button,
.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.react-datepicker__input-time-container
	.react-datepicker-time__input-container
	.react-datepicker-time__input
	input[type='time'] {
	-moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
	margin-left: 5px;
	display: inline-block;
}

.react-datepicker__time-container {
	float: right;
	border-left: 1px solid #aeaeae;
	width: 85px;
}

.react-datepicker__time-container--with-today-button {
	display: inline;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	position: absolute;
	right: -72px;
	top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
	position: relative;
	background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 85px;
	overflow-x: hidden;
	margin: 0 auto;
	text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
	list-style: none;
	margin: 0;
	height: calc(195px + (1.7rem / 2));
	overflow-y: scroll;
	padding-right: 0px;
	padding-left: 0px;
	width: 100%;
	box-sizing: content-box;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	height: 30px;
	padding: 5px 10px;
	white-space: nowrap;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	cursor: pointer;
	background-color: #f0f0f0;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: #216ba5;
	color: white;
	font-weight: bold;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover {
	background-color: #216ba5;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled {
	color: #ccc;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled:hover {
	cursor: default;
	background-color: transparent;
}

.react-datepicker__week-number {
	color: #ccc;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
	cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
	border-radius: 0.3rem;
	background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
	border-radius: 0.3rem;
	background-color: #216ba5;
	color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
	background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
	color: #ccc;
	pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
	cursor: default;
	background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
	cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	border-radius: 0.3rem;
	background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
	font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
	border-radius: 0.3rem;
	background-color: #3dcc4a;
	color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
	background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
	color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
	color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
	border-radius: 0.3rem;
	background-color: #216ba5;
	color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
	background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	border-radius: 0.3rem;
	background-color: #2a87d0;
	color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
	background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range {
	background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
	background-color: #f0f0f0;
	color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
	cursor: default;
	color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
	background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
	background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	background-color: #f0f0f0;
}

.react-datepicker__input-container {
	position: relative;
	display: inline-block;
	width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	border: 1px solid transparent;
	border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
	cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
	border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: #ccc;
	float: right;
	margin-left: 20px;
	top: 8px;
	position: relative;
	border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: #f0f0f0;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	z-index: 1;
	text-align: center;
	border-radius: 0.3rem;
	border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
	cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
	height: 150px;
	overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
	border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
	border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
	position: absolute;
	left: 15px;
}

.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0px 13px 0px 0px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.react-datepicker__close-icon::after {
	cursor: pointer;
	background-color: #a8abaf !important;
	color: #fff;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	padding: 2px;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	content: '\00d7';
}

.react-datepicker__today-button {
	background: #f0f0f0;
	border-top: 1px solid #aeaeae;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
	clear: left;
}

.react-datepicker__portal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
	width: 3rem;
	line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
	.react-datepicker__portal .react-datepicker__day-name,
	.react-datepicker__portal .react-datepicker__day,
	.react-datepicker__portal .react-datepicker__time-name {
		width: 2rem;
		line-height: 2rem;
	}
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
	font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
	border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
	border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
	border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
	border-right-color: #e6e6e6;
	cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
	border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
	border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
	border-left-color: #e6e6e6;
	cursor: default;
}


.centered-modal-pdf {
	max-width: 90% !important;
	
  }

.pdf-container-size{
	max-width: 100%;
	padding: 0%;
	margin: 0%;
}
  